import React, { PureComponent } from 'react'
import styled from 'styled-components'
import tachyons from 'styled-components-tachyons'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import Section from '../../components/Section'
import Wrapper from '../../components/Wrapper'
import Type, {
  Span,
  Subtitle,
  P,
  BulletList,
  ListItem,
} from '../../components/Type'

import { injectIntl, defineMessages } from 'gatsby-plugin-intl'
import { track as trackEvent } from '../../global/analytics'
import eventConstants from '../../global/eventConstants'

export const messageDescriptors = defineMessages({
  title: {
    id: 'pages.legal.privacy.title',
    defaultMessage: 'Privacy Policy',
  },
  lasUpdated: {
    id: 'pages.legal.privacy.lasUpdated',
    defaultMessage: 'Updated January 2023',
  },
  paragraph1: {
    id: 'pages.legal.privacy.paragraph1',
    defaultMessage: `Unroll.me is a service that is designed to help you manage your email inboxes 
    through features that, for example, manage your email subscriptions, suppress spam, 
    and consolidate promotional, transactional and other commercial emails that you receive (the “Service”). 
    Unroll.me is part of Nielsen Consumer LLC (NielsenIQ), based in Chicago, Illinois.`,
  },
  paragraph1P2: {
    id: 'pages.legal.privacy.paragraph1P2',
    defaultMessage: `As the controller of the personal data that is processed 
    in connection with your use of the Service, NielsenIQ is committed to 
    handling your data in an open and transparent manner and protecting your privacy.`,
  },
  list1Title: {
    id: 'pages.legal.privacy.list1Title',
    defaultMessage: 'This privacy policy describes:'
  },
  list1Point1: {
    id: 'pages.legal.privacy.list1Point1',
    defaultMessage: `the information we collect through our websites and apps;`,
  },
  list1Point2: {
    id: 'pages.legal.privacy.list1Point2',
    defaultMessage: 'how we use this information;',
  },
  list1Point3: {
    id: 'pages.legal.privacy.list1Point3',
    defaultMessage: 'to whom we disclose it; and',
  },
  list1Point4: {
    id: 'pages.legal.privacy.list1Point4',
    defaultMessage: 'and how we protect it.',
  },
  paragraph2: {
    id: 'pages.legal.privacy.paragraph2',
    defaultMessage:
      'This privacy policy also provides you with information about:',
  },
  list2Point1: {
    id: 'pages.legal.privacy.list2Point1',
    defaultMessage: 
      `how you can request to access, stop the collection of, 
      or delete your personal information (information that can be used to locate or identify you);`,
  },
  list2Point2: {
    id: 'pages.legal.privacy.list2Point2',
    defaultMessage:
      `how to opt-out of the use of your personal information in our
       measurement products and datasets; and`
  },
  list2Point3: {
    id: 'pages.legal.privacy.list2Point3',
    defaultMessage:
      'where to go if you have questions about our privacy practices.',
  },
  quickLinksTitle: {
    id: 'pages.legal.privacy.quickLinksTitle',
    defaultMessage: `Quick Links`
  },
  subtitle1: {
    id: 'pages.legal.privacy.subtitle1',
    defaultMessage: 'What Information We Collect',
  },
  paragraph3: {
    id: 'pages.legal.privacy.paragraph3',
    defaultMessage: 'As part of our Services, we collect the following information:',
  },
  list3Point1: {
    id: 'pages.legal.privacy.list3Point1',
    defaultMessage: 'identifier information such as your name and email address;',
  },
  list3Point2: {
    id: 'pages.legal.privacy.list3Point2',
    defaultMessage:
      `information about your subscriptions, commercial transactions and the
      promotions that you receive by email; and`
  },
  list3Point3: {
    id: 'pages.legal.privacy.list3Point3',
    defaultMessage: 
      `information about your interaction with our website and apps including
      for example, technical details about the devices you use with our
      Service (such as IP address or other device identifier), usage
      information and statistics about your interaction with our websites, the
      URLs of our web pages that you visit, URLs of referring and exiting
      pages, page views, time spent on a page, number of clicks, platform
      type, and other information about how you use our Service.`
  },
  paragraph4P1: {
    id: 'pages.legal.privacy.paragraph4P1',
    defaultMessage: 'How We Collect This Information',
  },
  paragraph4P2: {
    id: 'pages.legal.privacy.paragraph4P2',
    defaultMessage: `We collect information from you in several ways:`,
  },
  list4Point1: {
    id: 'pages.legal.privacy.list4Point1',
    defaultMessage: 'you directly provide us with some information',
  },
  list4Point2: {
    id: 'pages.legal.privacy.list4Point2',
    defaultMessage: 'you authorize us to collect other types of information, and',
  },
  list4Point3: {
    id: 'pages.legal.privacy.list4Point3',
    defaultMessage: 'we automatically collect certain types of information.',
  },
  paragraph5P1: {
    id: 'pages.legal.privacy.paragraph5P1',
    defaultMessage: `Information you provide us: `,
  },
  paragraph5P2: {
    id: 'pages.legal.privacy.paragraph5P2',
    defaultMessage:
      `When you register for the Service, you provide
      us with basic contact information, such as name and email address. You may
      also provide us with information when you interact with us, such as:`
  },
  list5Point1: {
    id: 'pages.legal.privacy.list5Point1',
    defaultMessage: 'when you sign up for email alerts,',
  },
  list5Point2: {
    id: 'pages.legal.privacy.list5Point2',
    defaultMessage: 'subscribe to receive information,',
  },
  list5Point3: {
    id: 'pages.legal.privacy.list5Point3',
    defaultMessage:
      'complete a survey, or',
  },
  list5Point4: {
    id: 'pages.legal.privacy.list5Point4',
    defaultMessage:
      'seek customer support.',
  },
  paragraph6P0: {
    id: 'pages.legal.privacy.paragraph6P0',
    defaultMessage:
      `From time to time we will request additional information from you so that we
      can provide you with a specific offer, function, or feature.`
  },
  paragraph6P1: {
    id: 'pages.legal.privacy.paragraph6P1',
    defaultMessage: 'Information you authorize us to collect:',
  },
  paragraph6P2: {
    id: 'pages.legal.privacy.paragraph6P2',
    defaultMessage:
      `When you register for our Service,
      we ask you to provide us with access your email account(s), which permits us
      to process your subscriptions, commercial transactions, registration
      confirmations, and the promotions that you receive by email. You authorize
      this access by agreeing to this privacy policy and providing any consent
      required by your email provider.`,
  },
  paragraph7P1: {
    id: 'pages.legal.privacy.paragraph7P1',
    defaultMessage: 'Information that we automatically collect.',
  },
  paragraph7P2: {
    id: 'pages.legal.privacy.paragraph7P2',
    defaultMessage:
      `We may automatically collect
      information about the way that you use or interact with our Service.`,
  },
  paragraph8: {
    id: 'pages.legal.privacy.paragraph8',
    defaultMessage: `Here are some of the types of automated data collection that occur
              when you use our sites:`,
  },
  paragraph9P1: {
    id: 'pages.legal.privacy.paragraph9P1',
    defaultMessage: 'Web Browsers:',
  },
  paragraph9P2: {
    id: 'pages.legal.privacy.paragraph9P2',
    defaultMessage:
      `When you visit one of our websites, your browser
      automatically sends us your internet protocol (“IP”) address so that the web
      pages you request can be sent to your computer or device. We use your IP
      address to determine additional information, such as whether the computer or
      device has ever been used to visit our site before, which site features were
      used most often, and how much time was spent on a page.`,
  },
  paragraph10P1: {
    id: 'pages.legal.privacy.paragraph10P1',
    defaultMessage: 'Cookies:',
  },
  paragraph10P2: {
    id: 'pages.legal.privacy.paragraph10P2',
    defaultMessage:
      `We automatically collect information through “cookies,” which
      are text files placed on your device’s hard drive when you visit websites.
      Cookies can enhance your online experience by saving your preferences
      while you are visiting a particular site. We use cookies to help us manage and
      understand how you use our website. For example, we use cookies for
      authentication purposes, to measure session activity on the site, and to
      determine which areas and features of the site are the most popular. If you
      wish to disable cookies, you can do so by adjusting the settings for the
      browser that you use. If you do choose to disable cookies through your
      browser, you will not be able to take full advantage of all our site features.`,
  },
  paragraph11P1: {
    id: 'pages.legal.privacy.paragraph11P1',
    defaultMessage: 'Web Beacons and Log Files:',
  },
  paragraph11P2: {
    id: 'pages.legal.privacy.paragraph11P2',
    defaultMessage:
      `We may use “web beacons” (also known
      as internet tags, pixel tags, tracking pixels, and clear GIFs) on pages of our
      websites. These web beacons allow third parties to collect information such as
      the IP address of the device, the URL of the web page, the time the page was
      viewed, the type of browser used, and any additional information in cookies
      that may have been set by the third party. The information is typically
      collected in log files, which generally track traffic on our websites. We use web
      beacons to manage the content we present and for analytical purposes (such
      as understanding our users, and how they interact with our site, and assisting
      us in managing and improving our site and Service). We do not attempt to
      identify individual users through this information.`,
  },
  paragraph11P3: {
    id: 'pages.legal.privacy.paragraph11P3',
    defaultMessage: 'What type of Cookies do we Use?',
  },
  paragraph11P4: {
    id: 'pages.legal.privacy.paragraph11P4',
    defaultMessage:
      `The website/mobile app might use both first-party cookies 
      (which are set by the website being visited) and third-party cookies 
      (which are set by a server located outside the domain of our website and apps.`,
  },
  paragraph11P5: {
    id: 'pages.legal.privacy.paragraph11P5',
    defaultMessage: 'Strictly Necessary Cookies',
  },
  paragraph11P6: {
    id: 'pages.legal.privacy.paragraph11P6',
    defaultMessage:
      `These cookies are necessary for the website to function and cannot be switched off. 
      They are usually only set in response to actions made by you, 
      such as settings, logging in or filling in forms. 
      These cookies do not store any personally identifiable information and are placed by us 
      in connection with our legitimate interests in delivering the essential 
      functionality of our website. You can set your browser to block or alert 
      you about these cookies but some or all parts of the website might not work as intended.`,
  },
  paragraph11P7: {
    id: 'pages.legal.privacy.paragraph11P7',
    defaultMessage: 'Performance Cookies',
  },
  paragraph11P8: {
    id: 'pages.legal.privacy.paragraph11P8',
    defaultMessage:
      `These cookies allow us to count visits and identify traffic 
      sources so we can measure and improve the performance of our website. 
      They help us to know which pages are the most and least popular and see how 
      visitors navigate the website. Any information collected by these cookies 
      is usually aggregated and cannot be used to identify you. If you do not allow 
      these cookies, we will not know when you have visited our website and will be 
      unable to improve the website and apps performance based upon your participation as a user.`,
  },
  paragraph11P9: {
    id: 'pages.legal.privacy.paragraph11P9',
    defaultMessage: 'Functional Cookies',
  },
  paragraph11P10: {
    id: 'pages.legal.privacy.paragraph11P10',
    defaultMessage:
      `Functional cookies are mostly used to enhance the performance of 
      a website as without them certain functions may not be available. 
      Functional cookies are not vital for a website to run, but they allow 
      recording of user preferences and settings. These cookies enable the 
      website to provide enhanced functionality and personalization. 
      These cookies, which may be set by us or by third-party providers 
      whose services we have added to our website, collect information about 
      your choices and preferences and your usage of the website.`,
  },
  paragraph11P11: {
    id: 'pages.legal.privacy.paragraph11P11',
    defaultMessage: 'Targeting Cookies',
  },
  paragraph11P12: {
    id: 'pages.legal.privacy.paragraph11P12',
    defaultMessage:
      `These cookies may be set through our website by our advertising 
      partners to build a profile of your interests and to market products 
      and services to you on third-party website or to measure and to track 
      the effectiveness of our marketing efforts so that we can tailor our 
      advertising more effectively.`,
  },
  paragraph11P13: {
    id: 'pages.legal.privacy.paragraph11P13',
    defaultMessage: 'How do I manage cookies?',
  },
  paragraph11P14: {
    id: 'pages.legal.privacy.paragraph11P14',
    defaultMessage:
      `You can manage website cookies and other tracking technologies in 
      your website browser and, depending on your device, from your device settings. 
      If you do not wish to have data collected from your device by 
      tracking technologies, web browsers and mobile settings might allow 
      you to decline the use of cookies and/or similar tracking technologies. 
      If you choose to change your settings, you may find that certain functions 
      and features will not work as intended. All browser and device settings 
      are slightly different, so to manage cookies and other tracking technologies, 
      you should refer to the relevant settings within your browser or device`,
  },
  paragraph11P15: {
    id: 'pages.legal.privacy.paragraph11P15',
    defaultMessage:
      `If you'd like to remove or disable cookies through your browser or mobile device, 
      you may refer to your browser's documentation or mobile device settings since 
      the settings and steps for managing cookie preferences are usually browser or device specific.`,
  },
  paragraph11P16: {
    id: 'pages.legal.privacy.paragraph11P16',
    defaultMessage:
      `Cookies used on our website/ mobile application`,
  },
  paragraph11P17: {
    id: 'pages.legal.privacy.paragraph11P17',
    defaultMessage:
      `Functional Cookies`,
  },
  paragraph11P18: {
    id: 'pages.legal.privacy.paragraph11P18',
    defaultMessage:
      `We may update the list of Cookies used on this list from time to time 
      in accordance with our updates to the Privacy Policy.
`,
  },

  paragraph12P1: {
    id: 'pages.legal.privacy.paragraph12P1',
    defaultMessage: 'Do Not Track Browser Signals.',
  },
  paragraph12P2: {
    id: 'pages.legal.privacy.paragraph12P2',
    defaultMessage:
      `At this time, our Service does not
      recognize automated browser signals regarding tracking mechanisms, which
      may include “do not track” instructions. However, you may be able to change
      your privacy preferences regarding the use of cookies and similar
      technologies through your browser, as described above.`,
  },
  paragraph12P3: {
    id: 'pages.legal.privacy.paragraph12P3',
    defaultMessage:
      `Please note that if there are features of our Services that are provided by third
      parties, those parties also may use automated means of data collection that
      are subject to those third parties’ privacy notices and policies.`
  },
  howWeUseHeading: {
    id: 'pages.legal.privacy.howWeUseHeading',
    defaultMessage:
      `How We Use Collected Information`
  },
  howWeUseP1Title: {
    id: 'pages.legal.privacy.howWeUseP1Title',
    defaultMessage:
      `Information you provide us: `
  },
  howWeUseP1Desc: {
    id: 'pages.legal.privacy.howWeUseP1Desc',
    defaultMessage:
      `We use the information that you provide us to:`
  },
  howWeUseP1Point1: {
    id: 'pages.legal.privacy.howWeUseP1Point1',
    defaultMessage:
      `bring you our email management service,`
  },
  howWeUseP1Point2: {
    id: 'pages.legal.privacy.howWeUseP1Point2',
    defaultMessage:
      `allow you to create and manage your account with us,`
  },
  howWeUseP1Point3: {
    id: 'pages.legal.privacy.howWeUseP1Point3',
    defaultMessage:
      `contact you about updates to and offers connected with the Service,`
  },
  howWeUseP1Point4: {
    id: 'pages.legal.privacy.howWeUseP1Point4',
    defaultMessage:
      `provide you with customer service and technical support, and`
  },
  howWeUseP1Point5: {
    id: 'pages.legal.privacy.howWeUseP1Point5',
    defaultMessage:
      `provide you with important notices about the Service.`
  },
  howWeUseP2Title: {
    id: 'pages.legal.privacy.howWeUseP2Title',
    defaultMessage:
      `Information you authorize us to collect: `
  },
  howWeUseP2Desc: {
    id: 'pages.legal.privacy.howWeUseP2Desc',
    defaultMessage:
      `When we access your email account, we use this access to:`
  },
  howWeUseP2Point1: {
    id: 'pages.legal.privacy.howWeUseP2Point1',
    defaultMessage:
      `perform the functions of the Service, such as blocking you 
      unwanted subscriptions and creating a “roll-up” of other subscriptions
      and/or transactions;`
  },
  howWeUseP2Point2: {
    id: 'pages.legal.privacy.howWeUseP2Point2',
    defaultMessage:
      `authenticate you as part of our security processes;`
  },
  howWeUseP2Point3: {
    id: 'pages.legal.privacy.howWeUseP2Point3',
    defaultMessage:
      `develop and offer new services; and`
  },
  howWeUseP2Point4: {
    id: 'pages.legal.privacy.howWeUseP2Point4',
    defaultMessage:
      `tailor content as part of our Service such as in our roll-up summaries`
  },
  howWeUseP2FooterP1: {
    id: 'pages.legal.privacy.howWeUseP2FooterP1',
    defaultMessage:
      `Additionally, unless otherwise `
  },
  howWeUseP2FooterP2: {
    id: 'pages.legal.privacy.howWeUseP2FooterP2',
    defaultMessage:
      `restricted`
  },
  howWeUseP2FooterP3: {
    id: 'pages.legal.privacy.howWeUseP2FooterP3',
    defaultMessage:
      ` by your email provider, we use this
      access to support our e-commerce measurement business.`
  },
  howWeUseMeasurementTitle: {
    id: 'pages.legal.privacy.howWeUseMeasurementTitle',
    defaultMessage:
      `Measurement Products and Datasets:`
  },
  howWeUseMeasurementDesc: {
    id: 'pages.legal.privacy.howWeUseMeasurementDesc',
    defaultMessage:
      `We access information from
      transaction-related emails that you receive to develop and prepare
      measurement products and datasets with the assistance of, and in
      combination with, data that is available to: our parent company, our affiliate
      companies, and other trusted business partners. Examples of the transaction-related emails that we or our trusted business partners process include
      purchase receipts, sales receipts, delivery confirmations and returns,
      subscription confirmations and cancellations, registration confirmations,
      transaction summaries and the like. We also access information from
      promotional emails sent to you to report on trends in offers and other
      marketing and advertising being sent by email.`
  },
  howWeUseMeasurementPara1DescP1: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara1DescP1',
    defaultMessage:
      `Data security and privacy are a priority for us, and with the assistance of our
      parent company, our affiliated companies and other trusted business partners,
      unless otherwise`
  },
  howWeUseMeasurementPara1DescP2: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara1DescP2',
    defaultMessage:
      `restricted`
  },
  howWeUseMeasurementPara1DescP3: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara1DescP3',
    defaultMessage:
      `by your email service provider:`
  },
  howWeUseMeasurementPara1Point1: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara1Point1',
    defaultMessage:
      `we undertake a process that is designed to obscure or replace directly
      identifiable information with a unique identifier;`
  },
  howWeUseMeasurementPara1Point2: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara1Point2',
    defaultMessage:
      `we may further combine or aggregate this information with other
      information available to us or our trusted business partners; and once
      the information is processed, and`
  },
  howWeUseMeasurementPara1Point3: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara1Point3',
    defaultMessage:
      `we use this processed information as part of our e-commerce
      measurement products and datasets`
  },
  howWeUseMeasurementPara2Desc: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara2DescP3',
    defaultMessage:
      `The measurement products and datasets that we prepare is provided to
      Fortune 500 companies, household name brands, investment companies, and
      other businesses for purposes such as:`
  },
  howWeUseMeasurementPara2Point1: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara2Point1',
    defaultMessage:
      `understanding industry and business trends;`
  },
  howWeUseMeasurementPara2Point2: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara2Point2',
    defaultMessage:
      `improving the goods, services or offers provided to customers;`
  },
  howWeUseMeasurementPara2Point3: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara2Point3',
    defaultMessage:
      `improving business operations;`
  },
  howWeUseMeasurementPara2Point4: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara2Point4',
    defaultMessage:
      `understanding the competitive landscape;`
  },
  howWeUseMeasurementPara2Point5: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara2Point5',
    defaultMessage:
      `understanding where to make investments; or`
  },
  howWeUseMeasurementPara2Point6: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara2Point6',
    defaultMessage:
      `gaining other business insights.`
  },
  howWeUseMeasurementPara3Title: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara3Title',
    defaultMessage:
      `Information we automatically collect:`
  },
  howWeUseMeasurementPara3Desc: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara3Desc',
    defaultMessage:
      `We use the information that we
      automatically collect (through web browsers, cookies, web beacons and
      pixels) so that we can:`
  },
  howWeUseMeasurementPara3Point1: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara3Point1',
    defaultMessage:
      `tailor content within our Service, such as in roll-up summaries;`
  },
  howWeUseMeasurementPara3Point2: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara3Point2',
    defaultMessage:
      `provide users with access to our website, app, service, or feature;`
  },
  howWeUseMeasurementPara3Point3: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara3Point3',
    defaultMessage:
      `better understand our users, their interests and preferences; and`
  },
  howWeUseMeasurementPara3Point4: {
    id: 'pages.legal.privacy.howWeUseMeasurementPara3Point4',
    defaultMessage:
      `develop, deliver, and improve the services we offer.`
  },
  infoWeShareHeading: {
    id: 'pages.legal.privacy.infoWeShareHeading',
    defaultMessage:
      `Information That We Disclose`
  },
  infoWeSharePara1: {
    id: 'pages.legal.privacy.infoWeSharePara1',
    defaultMessage:
      `We disclose the information we collect to our parent company and service
      providers, who help us with the processing and storage of your data and
      otherwise operate and improve our Service.`
  },
  infoWeSharePara2P1: {
    id: 'pages.legal.privacy.infoWeSharePara2P1',
    defaultMessage:
      `Additionally, unless otherwise`
  },
  infoWeSharePara2P2: {
    id: 'pages.legal.privacy.infoWeSharePara2P2',
    defaultMessage:
      `restricted`
  },
  infoWeSharePara2P3: {
    id: 'pages.legal.privacy.infoWeSharePara2P3',
    defaultMessage:
      `by your email provider, we disclose the
      information that we collect to:`
  },
  infoWeShareList1Point1: {
    id: 'pages.legal.privacy.infoWeShareList1Point1',
    defaultMessage:
      `our parent company, who helps us develop measurement products and datasets;`
  },
  infoWeShareList1Point2: {
    id: 'pages.legal.privacy.infoWeShareList1Point2',
    defaultMessage:
      `our affiliated companies, who enhance our measurement capabilities
      and theirs by combining the information that we collect with other
      information available to them;`
  },
  infoWeShareList1Point3: {
    id: 'pages.legal.privacy.infoWeShareList1Point3',
    defaultMessage:
      `service providers and trusted business partners that work with us to
      develop and resell measurement products; and`
  },
  infoWeShareList1Point4: {
    id: 'pages.legal.privacy.infoWeShareList1Point4',
    defaultMessage:
      `customers (including third parties and affiliates) that have access to our
      measurement products and datasets`
  },
  infoWeSharePara3: {
    id: 'pages.legal.privacy.infoWeSharePara3',
    defaultMessage:
      `We may also disclose information about you:`
  },
  infoWeShareList2Point1: {
    id: 'pages.legal.privacy.infoWeShareList2Point1',
    defaultMessage:
      `if we are required to do so by law or legal process,`
  },
  infoWeShareList2Point2: {
    id: 'pages.legal.privacy.infoWeShareList2Point2',
    defaultMessage:
      `to law enforcement authorities or other government officials,`
  },
  infoWeShareList2Point3: {
    id: 'pages.legal.privacy.infoWeShareList2Point3',
    defaultMessage:
      `when we believe disclosure is necessary or appropriate to prevent
      physical harm or financial loss or in connection with an investigation of
      suspected or actual illegal activity, or`
  },
  infoWeShareList2Point4: {
    id: 'pages.legal.privacy.infoWeShareList2Point4',
    defaultMessage:
      `if necessary to protect the vital interests of another individual.`
  },
  infoWeSharePara4: {
    id: 'pages.legal.privacy.infoWeSharePara4',
    defaultMessage:
      `Additionally, we reserve the right to transfer personal information we have
      about you in the event we sell or transfer all or a portion of our business or
      assets. This Privacy Policy will apply to your personal information that is 
      shared with (before and after the close of any transaction) or transferred to the new entity.  
`
  },
  howWeProtectHeading: {
    id: 'pages.legal.privacy.howWeProtectHeading',
    defaultMessage:
      `How We Protect Your Information`
  },
  howWeProtectPara1: {
    id: 'pages.legal.privacy.howWeProtectPara1',
    defaultMessage:
      `We maintain administrative, technical and physical safeguards to protect your
      personal information from accidental, unlawful or unauthorized destruction,
      loss, alteration, access, disclosure or use and other unlawful forms of
      processing. This includes the necessary physical, electronic and managerial
      procedures designed to protect data integrity, access and use. Please note
      that no method of transmission over the Internet or method of electronic storage
      is 100% secure. As a result, we strive to protect your personal information but
      cannot ensure or warrant the security of your information against any
      possible loss or unauthorized access.`
  },
  howWeProtectPara2: {
    id: 'pages.legal.privacy.howWeProtectPara2',
    defaultMessage:
      `This said, no data transmissions through the internet or via mobile
      devices, or even the physical transfer of information, can be guaranteed to be
      completely secure. We cannot fully eliminate all security risks associated with
      personal information. As such, we do not ensure or warrant the security of any
      data or information you transmit to us, and you do so at your own risk.`
  },
  restrictionsHeading: {
    id: 'pages.legal.privacy.restrictionsHeading',
    defaultMessage:
      `Restrictions`
  },
  restrictionsPara1: {
    id: 'pages.legal.privacy.restrictionsPara1',
    defaultMessage:
      `For users that signed into this Service through their Gmail account, Google
      restricts third parties from using its API tool to access Gmail account
      information ("Your Google User Data"). This restriction will not apply to other
      Gmail users that sign up by providing us with an app password that was
      generated specifically as part of Google’s two-factor authentication process.`
  },
  restrictionsPara2P1: {
    id: 'pages.legal.privacy.restrictionsPara2P1',
    defaultMessage:
      `To determine whether you are subject to this restriction, please go to the
      settings page of your Unroll.Me dashboard which can be accessed`
  },
  restrictionsPara2P2: {
    id: 'pages.legal.privacy.restrictionsPara2P2',
    defaultMessage:
      `here.`
  },
  restrictionsPara3P1: {
    id: 'pages.legal.privacy.restrictionsPara3P1',
    defaultMessage:
      `Additional Limits on Use of Your Google User Data:`
  },
  restrictionsPara3P2: {
    id: 'pages.legal.privacy.restrictionsPara3P2',
    defaultMessage:
      `The following limits
      apply to information that we collect and process after June 1, 2019:`
  },
  restrictionsPara4: {
    id: 'pages.legal.privacy.restrictionsPara4',
    defaultMessage:
      `Notwithstanding anything else in this Privacy Policy, if you provide the Service
      with access to the following types of Your Google User Data, the Service’s
      use of that data will be subject to these additional restrictions:`
  },
  restrictionsPara5: {
    id: 'pages.legal.privacy.restrictionsPara5',
    defaultMessage:
      `The Service will only use access to read, write, modify or control Gmail
      message bodies (including attachments), metadata, headers, and settings to
      provide you with the Service and will not transfer this Gmail data to others
      unless doing so is necessary to provide and improve these features, comply
      with applicable law, or as part of a merger, acquisition, or sale of assets. The
      Service will not use this Gmail data for serving advertisements. The Service
      will not allow humans to read this data unless we have your affirmative
      agreement for specific messages, doing so is necessary for security purposes
      such as investigating abuse, to comply with applicable law, or for the Service’s
      internal operations and even then only when the data have been aggregated and anonymized.`
  },
  choicesHeading: {
    id: 'pages.legal.privacy.choicesHeading',
    defaultMessage:
      `Your Choices and Rights`
  },
  choicesPara1: {
    id: 'pages.legal.privacy.choicesPara1',
    defaultMessage:
      `If you wish to access or request deletion of your personal information, you can
      do so by editing your information in the Settings section of our dashboard.`
  },
  choicesPara2: {
    id: 'pages.legal.privacy.choicesPara2',
    defaultMessage:
      `We will provide you with the ability to access certain information we have
      about you in the Settings section of our dashboard after you have logged into
      our app.`
  },
  choicesPara3: {
    id: 'pages.legal.privacy.choicesPara3',
    defaultMessage:
      `You can also choose not to give us the information we request, as described
      in the “Information You Provide to Us” section of this policy.`
  },
  choicesPara4: {
    id: 'pages.legal.privacy.choicesPara4',
    defaultMessage:
      `In some cases, you can stop or limit the information we collect by automated
      means. Please review the sections of this policy titled “Information Collected
      by Automated Means” to learn more about how you may be able to stop or
      limit our receipt of that information.`
  },
  choicesPara5P1: {
    id: 'pages.legal.privacy.choicesPara5P1',
    defaultMessage:
      `Depending on your U.S. state of residence, including specifically 
      users in Colorado, Connecticut, Utah, and Virginia, you may have 
      the right to request details about the Personal Information that 
      we hold about you in a portable format and the right to request that 
      we update or correct your Personal Information `
  },
  choicesPara5P01: {
    id: 'pages.legal.privacy.choicesPara5P01',
    defaultMessage:
      `If you are interested in exercising one or more of the rights 
      described above, please submit a request to us by clicking`
  },
  choicesPara5P2: {
    id: 'pages.legal.privacy.choicesPara5P2',
    defaultMessage:
      `here.`
  },
  choicesPara6P1: {
    id: 'pages.legal.privacy.choicesPara6P1',
    defaultMessage:
      `In addition to submitting a request or exercising your choices
       through the Settings section, You may also delete your account 
       by following these`
  },
  choicesPara6P2: {
    id: 'pages.legal.privacy.choicesPara6P2',
    defaultMessage:
      `instructions.`
  },
  choicesPara7: {
    id: 'pages.legal.privacy.choicesPara7',
    defaultMessage:
      `As an alternative to exercising your choices through the Settings section or
      your dashboard, you may also exercise and amend your choices (including
      access, delete, and opt-out) by sending an email to us as specified in the
      “How to Contact Us” section below.`
  },
  choicesPara8: {
    id: 'pages.legal.privacy.choicesPara8',
    defaultMessage:
      [`Note that we may need to request additional information from you to 
      verify your identity or understand the scope of your request, 
      although you will not be required to create an account with us 
      to submit a request or have it fulfilled. We will require you to 
      provide, at a minimum your email address as well as completion 
      of the required fields on the webform located `,
        <a
        href="https://support.unroll.me/hc/en-us/requests/new?ticket_form_id=360000640791"
        target="_blank"
        rel="noopener noreferrer">
        here.
      </a>,
        ` You may 
      designate an authorized agent to make a request on your behalf by 
      having the agent complete the webform located `,
        <a
          href="https://support.unroll.me/hc/en-us/requests/new?ticket_form_id=360000640791"
          target="_blank"
          rel="noopener noreferrer">
          here.
        </a>
        , ` We do not 
      knowingly sell personal information of minors under 16 years of age`]
  },
  choicesPara9: {
    id: 'pages.legal.privacy.choicesPara9',
    defaultMessage:
      `Please note that we will not discriminate against you if you choose to exercise 
      any of the above-listed rights available to you under applicable law. 
      In some cases, if you decide not to provide information, we will not be able 
      to provide you with access to the site you requested, or we may not be able 
      to provide you with the service, feature or information you requested. 
      For example, We are unable to delete your information without also deleting your account.`
  },
  linksHeading: {
    id: 'pages.legal.privacy.linksHeading',
    defaultMessage:
      `Links to Other Sites`
  },
  linksPara1: {
    id: 'pages.legal.privacy.linksPara1',
    defaultMessage:
      `We may provide links to other sites for your convenience and information.
      These sites may be operated by organizations not affiliated with Unroll.me, so
      we cannot control and are not responsible for their content, operation, or
      privacy practices. Those organizations may have their own privacy policies,
      which we strongly suggest you review if you use other sites. This Notice does
      not apply to those sites, even if we link to them or they link to us.`
  },
  transfersHeading: {
    id: 'pages.legal.privacy.transfersHeading',
    defaultMessage:
      `Transfers of Information`
  },
  transfersPara1: {
    id: 'pages.legal.privacy.transfersPara1',
    defaultMessage:
      `As part of our international operations, we may transfer information about you
      to a jurisdiction where we do business.`
  },
  transfersPara2P1: {
    id: 'pages.legal.privacy.transfersPara2P1',
    defaultMessage:
      `These countries may have data protection laws that are different 
      to the laws of your country (and, in some cases, may not be as 
        protective). However, we have taken appropriate safeguards 
        (such as contractual commitments) to require that your personal 
        information will remain protected in accordance with this Privacy 
        Policy. For data transfers to entities in countries that have not been 
        deemed to provide an adequate level of protection by the European 
        Commission, we may use specific contracts promulgated by the European 
        Commission, such as the Standard Contractual Clauses. Alternatively, 
        in certain circumstances, we may transfer your personal data on the 
        basis of a specific derogation or exemption recognized under applicable 
        data protection law. For further details relating to the transfers described 
        above and the adequate safeguards used with respect to such transfers, please 
        email us at `
  },
  transfersPara2P2: {
    id: 'pages.legal.privacy.transfersPara2P2',
    defaultMessage:
      `. To learn more about NielsenIQ’s group of companies, please click `
  },
  transfersPara2P3: {
    id: 'pages.legal.privacy.transfersPara2P3',
    defaultMessage:
      `here`
  },
  rightsHeading: {
    id: 'pages.legal.privacy.rightsHeading',
    defaultMessage:
      `Rights of California Residents`
  },
  rightsPara1P1: {
    id: 'pages.legal.privacy.rightsPara1P1',
    defaultMessage:
      `This section contains disclosures required by the 
      California Consumer Privacy Act, as amended by the 
      California Privacy Rights Act (collectively, “CPRA”), 
      and applies only to “personal information” that is subject to the CPRA.`
  },
  rightsPara1P2: {
    id: 'pages.legal.privacy.rightsPara1P2',
    defaultMessage:
      `In this section, "sell" and "sale" means selling, renting, releasing, 
      disclosing, disseminating, making available, transferring, or otherwise 
      communicating orally, in writing, or by electronic or other means, 
      a consumer's personal information by the business to a third party 
      for valuable consideration. This means that We may have received some 
      kind of benefit in return you’re your personal information, but not 
      necessarily a monetary benefit.`
  },
  rightsPara1P3: {
    id: 'pages.legal.privacy.rightsPara1P3',
    defaultMessage:
      `In this section “Share,” or “shared,” means sharing, 
      disclosing, or otherwise making available your personal 
      information to a third party for cross-context behavioral advertising, 
      but not necessarily a monetary benefit.`
  },
  rightsPara2P1: {
    id: 'pages.legal.privacy.rightsPara2P1',
    defaultMessage:
      `Sensitive Personal Information.`
  },
  rightsPara2P2: {
    id: 'pages.legal.privacy.rightsPara2P2',
    defaultMessage:
      `Under the CPRA the contents of your email are classified as sensitive 
      personal information. Therefore, the transaction-related emails that you 
      authorize us to collect by signing up for the service might generally be 
      considered sensitive personal information under the CPRA. We may also collect 
      sensitive personal information relating to your health, sex life, or sexual 
      orientation, if such information is contained in the transaction-related 
      emails that you authorize us to collect by signing up for the service. `
  },
  rightsPara2P3: {
    id: 'pages.legal.privacy.rightsPara2P5',
    defaultMessage:
      `Personal Information We Collected. `
  },
  rightsPara2P4: {
    id: 'pages.legal.privacy.rightsPara2P4',
    defaultMessage:
      `As further set forth in the chart below, in the past 12 months, 
      we have collected, the following categories of California consumers’ 
      personal information.`
  },
  rightsPara2P5: {
    id: 'pages.legal.privacy.rightsPara2P5',
    defaultMessage:
      `Personal Information We Sold or Shared. `
  },
  rightsPara2P6: {
    id: 'pages.legal.privacy.rightsPara2P6',
    defaultMessage:
      `As further set forth in the chart below, in the past 12 months, 
      we have sold or shared the following categories of California consumers’ 
      personal information.
`
  },
  rightsPara2P7: {
    id: 'pages.legal.privacy.rightsPara2P7',
    defaultMessage:
      `Personal Information We Disclosed.`
  },
  rightsPara2P8: {
    id: 'pages.legal.privacy.rightsPara2P8',
    defaultMessage:
      `As further set forth in the chart below, in the past 12 months, 
      we have disclosed the following categories of California consumers’ 
      personal information.`
  },

  rightsPersonalHeading: {
    id: 'pages.legal.privacy.rightsPersonalHeading',
    defaultMessage:
      `Your Rights Regarding Personal Information.`
  },
  rightsPersonalPara1: {
    id: 'pages.legal.privacy.rightsPersonalPara1',
    defaultMessage:
      `California residents have
      certain rights with respect to the personal information collected by businesses.
      If you are a California resident, you may exercise the following rights
      regarding your personal information, subject to certain exceptions and
      limitations:`
  },
  rightsPersonalList1Point1: {
    id: 'pages.legal.privacy.rightsPersonalList1Point1',
    defaultMessage:
      [`The `, <b>right to know </b>,`what personal information the business has collected about you, 
      including the categories of personal information, the categories of sources from which 
      the personal information is collected, the business or commercial purpose for collecting, 
      selling, or sharing personal information, the categories of third parties to whom 
      the business discloses personal information, and the specific pieces of personal 
      information the business has collected about you;`]
  },
  rightsPersonalList1Point2: {
    id: 'pages.legal.privacy.rightsPersonalList1Point2',
    defaultMessage:
      [`The `, <b>right to request that we delete</b>, ` the personal information we have collected from you or maintain about you.`]
  },
  rightsPersonalList1Point3: {
    id: 'pages.legal.privacy.rightsPersonalList1Point3',
    defaultMessage:
      [`The right to `, <b>correct</b>, ` inaccurate personal information that we maintain about you.`]
  },
  rightsPersonalList1Point4: {
    id: 'pages.legal.privacy.rightsPersonalList1Point4',
    defaultMessage:
      [`The right to `, <b>opt out of our sale(s)</b>, ` or sharing of your personal information. Please note that if you opt out of certain types of sales or sharing, we will be unable to provide you with the services that rely on such sales or sharing.`]
  },
  rightsPersonalList1Point5: {
    id: 'pages.legal.privacy.rightsPersonalList1Point5',
    defaultMessage:
      [`The `, <b>right not to receive discriminatory treatment</b>, ` for the exercise of the privacy rights conferred by the CCPA.`]
  },
  rightsPersonalList1Point6: {
    id: 'pages.legal.privacy.rightsPersonalList1Point6',
    defaultMessage:
      [`The `, <b>right to limit</b>, ` the use of your sensitive personal information to only that which is necessary for providing you products or services.`]
  },
  rightsPersonalPara2: {
    id: 'pages.legal.privacy.rightsPersonalPara2',
    defaultMessage:
      `To exercise any of the above rights, please contact us using the following information
      and submit the required verifying information, as further described below:`
  },
  rightsPersonalList2Point1P1: {
    id: 'pages.legal.privacy.rightsPersonalList2Point1P1',
    defaultMessage:
      `Online using this`
  },
  rightsPersonalList2Point1P2: {
    id: 'pages.legal.privacy.rightsPersonalList2Point1P2',
    defaultMessage:
      `webform`
  },
  rightsPersonalPara3P1: {
    id: 'pages.legal.privacy.rightsPersonalPara3P1',
    defaultMessage:
      `Note that we may need to request additional information from you to verify your
      identity or understand the scope of your request, although you will not be required
      to create an account with us to submit a request or have it fulfilled.  We will
      require you to provide, at a minimum your email address as well as
      completion of the required fields on the webform located`
  },
  rightsPersonalPara3P2: {
    id: 'pages.legal.privacy.rightsPersonalPara3P2',
    defaultMessage:
      `here.`
  },
  rightsPersonalPara3P3: {
    id: 'pages.legal.privacy.rightsPersonalPara3P3',
    defaultMessage:
      `You may designate an authorized agent to make a request on your behalf by having the agent complete the webform located`
  },
  rightsPersonalPara3P4: {
    id: 'pages.legal.privacy.rightsPersonalPara3P4',
    defaultMessage:
      `here.`
  },
  rightsPersonalPara3P5: {
    id: 'pages.legal.privacy.rightsPersonalPara3P5',
    defaultMessage:
      `We do not knowingly sell personal information of minors under 16 years of age.`
  },
  rightsTableRow1Col1: {
    id: 'pages.legal.privacy.rightsTableRow1Col1',
    defaultMessage:
      `Categories of Personal Information`
  },
  rightsTableRow1Col2: {
    id: 'pages.legal.privacy.rightsTableRow1Col2',
    defaultMessage:
      `Collected in the last 12 months:`
  },
  rightsTableRow1Col3: {
    id: 'pages.legal.privacy.rightsTableRow1Col3',
    defaultMessage:
      `Categories of sources from which information is collected:`
  },
  rightsTableRow1Col4: {
    id: 'pages.legal.privacy.rightsTableRow1Col4',
    defaultMessage:
      `Business or commercial purposes for collection, use, and sharing:`
  },
  rightsTableRow1Col5: {
    id: 'pages.legal.privacy.rightsTableRow1Col5',
    defaultMessage:
      `Disclosed for business purposes to the following categories of third parties:`
  },
  rightsTableRow1Col6: {
    id: 'pages.legal.privacy.rightsTableRow1Col6',
    defaultMessage:
      `Sold to the following categories of third parties:`
  },
  rightsTableRow2Col1: {
    id: 'pages.legal.privacy.rightsTableRow2Col1',
    defaultMessage:
      `Personal and online identifiers (such as first and last name, email
      address, or unique online identifiers)`
  },
  rightsTableRow2Col2: {
    id: 'pages.legal.privacy.rightsTableRow2Col2',
    defaultMessage:
      `Yes`
  },
  rightsTableRow2Col3: {
    id: 'pages.legal.privacy.rightsTableRow2Col3',
    defaultMessage:
      `Consumers Service Providers`
  },
  rightsTableRow2Col4: {
    id: 'pages.legal.privacy.rightsTableRow2Col4',
    defaultMessage:
      `Business or commercial purposes for collection, use, and sharing:`
  },
  rightsTableRow2Col5: {
    id: 'pages.legal.privacy.rightsTableRow2Col5',
    defaultMessage:
      `Disclosed for business purposes to the following categories of third parties:`
  },
  rightsTableRow2Col6: {
    id: 'pages.legal.privacy.rightsTableRow2Col6',
    defaultMessage:
      `Sold to the following categories of third parties:`
  },
  rightsTableRow3Col1: {
    id: 'pages.legal.privacy.rightsTableRow3Col1',
    defaultMessage:
      `Categories of Personal Information`
  },
  rightsTableRow3Col2: {
    id: 'pages.legal.privacy.rightsTableRow3Col2',
    defaultMessage:
      `Collected in the last 12 months:`
  },
  rightsTableRow3Col3: {
    id: 'pages.legal.privacy.rightsTableRow3Col3',
    defaultMessage:
      `Categories of sources from which information is collected:`
  },
  rightsTableRow3Col4: {
    id: 'pages.legal.privacy.rightsTableRow3Col4',
    defaultMessage:
      `Business or commercial purposes for collection, use, and sharing:`
  },
  rightsTableRow3Col5: {
    id: 'pages.legal.privacy.rightsTableRow3Col5',
    defaultMessage:
      `Disclosed for business purposes to the following categories of third parties:`
  },
  rightsTableRow3Col6: {
    id: 'pages.legal.privacy.rightsTableRow3Col6',
    defaultMessage:
      `Sold to the following categories of third parties:`
  },
  rightsTableRow4Col1: {
    id: 'pages.legal.privacy.rightsTableRow4Col1',
    defaultMessage:
      `Categories of Personal Information`
  },
  rightsTableRow4Col2: {
    id: 'pages.legal.privacy.rightsTableRow4Col2',
    defaultMessage:
      `Collected in the last 12 months:`
  },
  rightsTableRow4Col3: {
    id: 'pages.legal.privacy.rightsTableRow4Col3',
    defaultMessage:
      `Categories of sources from which information is collected:`
  },
  rightsTableRow4Col4: {
    id: 'pages.legal.privacy.rightsTableRow4Col4',
    defaultMessage:
      `Business or commercial purposes for collection, use, and sharing:`
  },
  rightsTableRow4Col5: {
    id: 'pages.legal.privacy.rightsTableRow4Col5',
    defaultMessage:
      `Disclosed for business purposes to the following categories of third parties:`
  },
  rightsTableRow4Col6: {
    id: 'pages.legal.privacy.rightsTableRow4Col6',
    defaultMessage:
      `Sold to the following categories of third parties:`
  },
  updatesHeading: {
    id: 'pages.legal.privacy.updatesHeading',
    defaultMessage:
      `Updates to Our Privacy Policy`
  },
  updatesPara1: {
    id: 'pages.legal.privacy.updatesPara1',
    defaultMessage:
      `This privacy policy may be updated periodically to you to reflect changes in
      our information practices or relevant laws. We will indicate at the top of the
      policy when it was updated. Substantial changes to this policy will be effective
      30 days after posting. Please review this policy any time you access or use
      our sites to make sure you have reviewed the most recent version.`
  },
  contactHeading: {
    id: 'pages.legal.privacy.contactHeading',
    defaultMessage:
      `How to Contact Us`
  },
  contactPara1: {
    id: 'pages.legal.privacy.contactPara1',
    defaultMessage:
      `If you have any questions or comments about this privacy policy or if you
      would like us to update information we have about you or your preferences,
      please contact us at`
  },
  contactPara2: {
    id: 'pages.legal.privacy.contactPara2',
    defaultMessage:
      `Alternatively, if you reside in the EEA/UK, please write to us at:`
  },
  contactPara3: {
    id: 'pages.legal.privacy.contactPara3',
    defaultMessage:
      `If you reside outside of the EEA/UK, please write to us at:`
  },
  rightsEEAHeading: {
    id: 'pages.legal.privacy.rightsEEAHeading',
    defaultMessage:
      `Rights of Individuals in the EEA and UK`
  },
  rightsEEAPara1: {
    id: 'pages.legal.privacy.rightsEEAPara1',
    defaultMessage:
      `If you are located in the European Economic Area or the United Kingdom, you have the following additional data protection rights:`
  },
  rightsEEAPara2Point1: {
    id: 'pages.legal.privacy.rightsEEAPara2Point1',
    defaultMessage:
      `You can object to processing of your personal data,`
  },
  rightsEEAPara2Point2: {
    id: 'pages.legal.privacy.rightsEEAPara2Point2',
    defaultMessage:
      `You can ask us to restrict processing of your personal data,`
  },
  rightsEEAPara2Point3: {
    id: 'pages.legal.privacy.rightsEEAPara2Point3',
    defaultMessage:
      `You can request portability of your personal data or `
  },
  rightsEEAPara2Point4: {
    id: 'pages.legal.privacy.rightsEEAPara2Point4',
    defaultMessage:
      `You can request deletion of your personal data`
  },
  rightsEEAPara3: {
    id: 'pages.legal.privacy.rightsEEAPara3',
    defaultMessage:
      `You can exercise these rights by please filling out the form
      located here and providing the verification information as stated
      on the form, or by contacting us using the contact details
      provided under the “How to Contact Us” heading below.`
  },
  rightsEEAPara4Part1: {
    id: 'pages.legal.privacy.rightsEEAPara4Part1',
    defaultMessage:
      `Similarly, if we have collected and process your personal information with your consent, then you can`
  },
  rightsEEAPara4Part2: {
    id: 'pages.legal.privacy.rightsEEAPara4Part2',
    defaultMessage:
      `withdraw your consent`
  },
  rightsEEAPara4Part3: {
    id: 'pages.legal.privacy.rightsEEAPara4Part3',
    defaultMessage:
      `at any time. Withdrawing your consent will not affect the lawfulness
      of any processing we conducted prior to your withdrawal, nor will it
      affect processing of your personal information conducted in reliance
      on lawful processing grounds other than consent. You can withdraw your
      consent by using the go to the data subject access request page and
      submitting a request or by contacting us using the contact details
      provided under the “How to Contact Us” heading below.`
  },
  rightsEEAPara5Part1: {
    id: 'pages.legal.privacy.rightsEEAPara5Part1',
    defaultMessage:
      `You have the right to complain to a data protection authority about our
      collection and use of your personal information. For more information,
      please contact your local data protection authority. (Contact details
      for data protection authorities in the European Economic Area are available`
  },
  rightsEEAPara5Part2: {
    id: 'pages.legal.privacy.rightsEEAPara5Part2',
    defaultMessage:
      `here`
  },
  rightsEEAPara5Part3: {
    id: 'pages.legal.privacy.rightsEEAPara5Part3',
    defaultMessage:
      `.) We would, however, appreciate an opportunity to address your complaint
      before you approach the supervisory authority so please contact us in the
      first instance with any complaints you may have by sending an email.`
  },
  rightsEEALegalHeading: {
    id: 'pages.legal.privacy.rightsEEALegalHeading',
    defaultMessage:
      `Legal Basis For Using EEA, UK Personal Data:`
  },
  rightsEEALegalPara1: {
    id: 'pages.legal.privacy.rightsEEALegalPara1',
    defaultMessage:
      `If you are located in the European Economic Area or United Kingdom, our legal basis
      for collecting and using the personal information described above
      will depend on the personal information concerned and the specific
      context in which we collect it. Usually, we rely on the following
      legal bases to process your personal information: `
  },
  rightsEEALegalPoint1: {
    id: 'pages.legal.privacy.rightsEEALegalPoint1',
    defaultMessage:
      `Consent: We process your personal data based on the consent
      you provide us when you register for our service.`
  },
  rightsEEALegalPoint2: {
    id: 'pages.legal.privacy.rightsEEALegalPoint2',
    defaultMessage:
      `Fulfillment of Contract: We will process the information you provide
      or make available to us to provide you with the Service.`
  },
  rightsEEALegalPoint3: {
    id: 'pages.legal.privacy.rightsEEALegalPoint3',
    defaultMessage:
      `Legal obligation: In some cases, we may also have a legal obligation
      to collect personal information from you or may otherwise need the
      personal information to protect your vital interests or those of another
      person. If we ask you to provide personal information to comply with a
      legal requirement, we will make this clear at the relevant time and advise
      you whether the provision of your personal information is mandatory or
      not (as well as of the possible consequences if you do not provide your
      personal information). If you have questions about or need further information
      concerning the legal basis on which we collect and use your personal information,
      please contact us using the contact details provided under the How to
      Contact Us section of this Privacy Policy.`
  },
  durationHeading: {
    id: 'pages.legal.privacy.durationHeading',
    defaultMessage:
      `Duration of Processing`
  },
  durationPara1: {
    id: 'pages.legal.privacy.durationPara1',
    defaultMessage:
      `We may actively process your personal information so long as you are active
      in our Service. We may, however, store your information for up to 7 full years
      after the date that you terminate your account in order to prepare the
      measurement products and services if you are participating in our measurement
      panel, and where we have an ongoing legitimate business need to do so
      (for example to respond to a query you requested or to comply with applicable
      legal, tax, audit and other requirements).  Where we are able, we store this
      information pseudonymized or anonymized form. When we have no ongoing
      legitimate business need to process your personal information, we will
      either delete or anonymize it or, if this is not possible (for example,
      because your personal information has been stored in backup archives),
      then we will securely store your personal information and isolate it
      from any further processing until deletion is possible.`
  },
})

const A = styled.a`
  font-weight: bold;
  display: block;
  text-decoration: underline;
  ${tachyons};
`

const TableContainer = styled.div`
  padding: 1rem;
  font-size: 12px;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      /* display: inline-block; */
      vertical-align: top;

      :last-child {
        border-right: 0;
      }
    }

    td:nth-child(1) {
      width: 18%;
    }

    td:nth-child(2) {
      width: 5%;
    }

    td:nth-child(3) {
      width: 13%;
    }

    td:nth-child(4) {
      width: 24%;
    }

    td:nth-child(5) {
      width: 20%;
    }

    td:nth-child(6) {
      width: 20%;
    }
  }
`

const track = (eventName, eventProps) => {
  trackEvent(eventName, {
    ...eventProps,
    Page: eventConstants.privacy.pageName
  })
}

class Privacy extends PureComponent {
  constructor(props) {
    super(props)
    this.restrictions = React.createRef()
  }

  componentDidMount() {
    if (window.location.hash === '#restrictions') {
      setTimeout(() => {
        window.scrollTo(0, this.restrictions.current.offsetTop)
      }, 0.001)
    }
    track(eventConstants.privacy.pageLoad)
  }

  render() {
    // TODO: fix internationlization, currently brokens
    // const { intl } = this.props
    // const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
    //   messages[key] = intl.formatMessage(messageDescriptors[key])
    //   return messages
    // }, {})

    const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
      messages[key] = messageDescriptors[key].defaultMessage
      return messages
    }, {})

    return (
      <Layout title={messages.title}>
        <Hero>
          <Section large>
            <Wrapper tc small>
              <Type variant="h1" f1_ns lh_copy dark_gray mb0>
                {messages.title}
              </Type>
            </Wrapper>
          </Section>
        </Hero>
        <Section large>
          <Wrapper small lh_copy>
            <P b>{messages.lasUpdated}</P>
            <P>{messages.paragraph1}</P>
            <P>{messages.paragraph1P2}</P>
            <P>{messages.list1Title}</P>
            <BulletList ml2>
              <ListItem>{messages.list1Point1}</ListItem>
              <ListItem>{messages.list1Point2}</ListItem>
              <ListItem>{messages.list1Point3}</ListItem>
              <ListItem>{messages.list1Point4}</ListItem>
            </BulletList>
            <P mt3>{messages.paragraph2}</P>
            <BulletList ml2>
              <ListItem>{messages.list2Point1}</ListItem>
              <ListItem>{messages.list2Point2}</ListItem>
              <ListItem>{messages.list2Point3}</ListItem>
            </BulletList>
            <P>
              {<br></br>}
              <Span b>{messages.quickLinksTitle}</Span>
            </P>
            <A href="#whatInformation">{messages.subtitle1}</A>
            <A href="#howWeCollectInfo">{messages.paragraph4P1}</A>
            <A href="#howWeUseInfo">{messages.howWeUseHeading}</A>
            <A href="#infoWeShare">{messages.infoWeShareHeading}</A>
            <A href="#howWeProtect">{messages.howWeProtectHeading}</A>
            <A href="#restrictions">{messages.restrictionsHeading}</A>
            <A href="#choices">{messages.choicesHeading}</A>
            <A href="#rights">{messages.rightsHeading}</A>
            <A href="#duration">{messages.durationHeading}</A>
            <A href="#transfers">{messages.transfersHeading}</A>
            <A href="#links">{messages.linksHeading}</A>
            <A href="#updates">{messages.updatesHeading}</A>
            <A href="#contact">{messages.contactHeading}</A>
            
            <div id="whatInformation" ref={this.whatInformation}>
              <Subtitle mt4>{messages.subtitle1}</Subtitle>
            </div>
            <P>
              {messages.paragraph3}
              <BulletList ml2>
                <ListItem>{messages.list3Point1}</ListItem>
                <ListItem>{messages.list3Point2}</ListItem>
                <ListItem>{messages.list3Point3}</ListItem>
              </BulletList>
            </P>
            <P mt3>
              <div id="howWeCollectInfo" ref={this.howWeCollectInfo}>
                <Subtitle>{messages.paragraph4P1}</Subtitle>{' '}
              </div>
              {messages.paragraph4P2}
            </P>
            <BulletList ml2>
              <ListItem>{messages.list4Point1}</ListItem>
              <ListItem>{messages.list4Point2}</ListItem>
              <ListItem>{messages.list4Point3}</ListItem>
            </BulletList>
            <P mt3>
              <Span b>{messages.paragraph5P1}</Span>
              {messages.paragraph5P2}
            </P>
            <BulletList ml2>
              <ListItem>{messages.list5Point1}</ListItem>
              <ListItem>{messages.list5Point2}</ListItem>
              <ListItem>{messages.list5Point3}</ListItem>
              <ListItem>{messages.list5Point4}</ListItem>
            </BulletList>
            <P mt3>
              <P>{messages.paragraph6P0}</P>
              <Span b>{messages.paragraph6P1}</Span>{' '}
              {messages.paragraph6P2}
            </P>
            <P mt3>
              <Span b>{messages.paragraph7P1}</Span>{' '}
              {messages.paragraph7P2}
            </P>
            <P mt3>{messages.paragraph8}</P>
            <P>
              <Span b>{messages.paragraph9P1}</Span>{' '}
              {messages.paragraph9P2}
            </P>
            <P>
              <Span b>{messages.paragraph10P1}</Span>{' '}
              {messages.paragraph10P2}
            </P>
            <P>
              <Span b>{messages.paragraph11P1}</Span>{' '}
              {messages.paragraph11P2}
            </P>
            <P>
              <Span b>{messages.paragraph11P3}</Span>{' '}<br/>
              {messages.paragraph11P4}
            </P>
            <P>
              <Span i>{messages.paragraph11P5}</Span>{' '}<br/>
              {messages.paragraph11P6}
            </P>
            <P>
              <Span i>{messages.paragraph11P7}</Span>{' '}<br/>
              {messages.paragraph11P8}
            </P>
            <P>
              <Span i>{messages.paragraph11P9}</Span>{' '}<br/>
              {messages.paragraph11P10}
            </P>
            <P>
              <Span i>{messages.paragraph11P11}</Span>{' '}<br/>
              {messages.paragraph11P12}
            </P>
            <P>
              <Span b>{messages.paragraph11P13}</Span>{' '}<br/>
              {messages.paragraph11P14}
            </P>
            <P>
              {messages.paragraph11P15}
            </P>
            <P>
              <Span b>{messages.paragraph11P16}</Span>{' '}<br/>
            </P>
            <P>
              <Span b>{messages.paragraph11P17}</Span>{' '}<br/>
              <TableContainer>
                <table>
                  <thead>
                  <tr>
                    <th>
                      Cookie name
                    </th>
                    <th>
                      Purpose
                    </th>
                    <th>
                      First Party/Third Party
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style={{width: "10%"}}>
                      Heap
                    </td>
                    <td style={{width: "35%"}}>
                      Establish Funnels and track/understand conversion metrics of the UX
                    </td>
                    <td style={{width: "10%"}}>
                      Third
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Google Analytics
                    </td>
                    <td>
                      Understand where traffic is coming from.
                    </td>
                    <td>
                      Third
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Adjust
                    </td>
                    <td>
                      Gives attribution to traffic brought in by ads, custom links.
                    </td>
                    <td>
                      Third
                    </td>
                  </tr>
                  </tbody>
                </table>
              </TableContainer>
              {messages.paragraph11P18}
            </P>
            <P>
              <Span b>{messages.paragraph12P1}</Span>{' '}
              {messages.paragraph12P2}
            </P>
            <P>{messages.paragraph12P3}</P>
            <P mt3>
              <div id="howWeUseInfo" ref={this.howWeUseInfo}>
                <Subtitle>{messages.howWeUseHeading}</Subtitle>{' '}
              </div>
              <Span b>{messages.howWeUseP1Title}</Span>
              {messages.howWeUseP1Desc}
            </P>
            <BulletList ml2>
              <ListItem>{messages.howWeUseP1Point1}</ListItem>
              <ListItem>{messages.howWeUseP1Point2}</ListItem>
              <ListItem>{messages.howWeUseP1Point3}</ListItem>
              <ListItem>{messages.howWeUseP1Point4}</ListItem>
              <ListItem>{messages.howWeUseP1Point5}</ListItem>
            </BulletList>
            <P mt3>
              <Span b>{messages.howWeUseP2Title}</Span>
              {messages.howWeUseP2Desc}
            </P>
            <BulletList ml2>
              <ListItem>{messages.howWeUseP2Point1}</ListItem>
              <ListItem>{messages.howWeUseP2Point2}</ListItem>
              <ListItem>{messages.howWeUseP2Point3}</ListItem>
              <ListItem>{messages.howWeUseP2Point4}</ListItem>
            </BulletList>
            <P>
              {messages.howWeUseP2FooterP1}{' '}
              <a href="#restrictions">{messages.howWeUseP2FooterP2}</a>
              {' '}{messages.howWeUseP2FooterP3}
            </P>
            <P mt3>
              <Span b>{messages.howWeUseMeasurementTitle}</Span>
              {' '}{messages.howWeUseMeasurementDesc}
            </P>
            <P mt3>
              {messages.howWeUseMeasurementPara1DescP1}{' '}
              <a href="#restrictions">{messages.howWeUseMeasurementPara1DescP2}</a>{' '}
              {messages.howWeUseMeasurementPara1DescP3}
            </P>
            <BulletList ml2>
              <ListItem>{messages.howWeUseMeasurementPara1Point1}</ListItem>
              <ListItem>{messages.howWeUseMeasurementPara1Point2}</ListItem>
              <ListItem>{messages.howWeUseMeasurementPara1Point3}</ListItem>
            </BulletList>
            <P mt3>
              {messages.howWeUseMeasurementPara2Desc}
            </P>
            <BulletList ml2>
              <ListItem>{messages.howWeUseMeasurementPara2Point1}</ListItem>
              <ListItem>{messages.howWeUseMeasurementPara2Point2}</ListItem>
              <ListItem>{messages.howWeUseMeasurementPara2Point3}</ListItem>
              <ListItem>{messages.howWeUseMeasurementPara2Point4}</ListItem>
              <ListItem>{messages.howWeUseMeasurementPara2Point5}</ListItem>
              <ListItem>{messages.howWeUseMeasurementPara2Point6}</ListItem>
            </BulletList>
            <P mt3>
              <Span b>{messages.howWeUseMeasurementPara3Title}</Span>
              {' '}{messages.howWeUseMeasurementPara3Desc}
            </P>
            <BulletList ml2>
              <ListItem>{messages.howWeUseMeasurementPara3Point1}</ListItem>
              <ListItem>{messages.howWeUseMeasurementPara3Point2}</ListItem>
              <ListItem>{messages.howWeUseMeasurementPara3Point3}</ListItem>
              <ListItem>{messages.howWeUseMeasurementPara3Point4}</ListItem>
            </BulletList>
            <P mt3>
              <div id="infoWeShare" ref={this.infoWeShare}>
                <Subtitle>{messages.infoWeShareHeading}</Subtitle>{' '}
              </div>
              <P>{messages.infoWeSharePara1}</P>
              <P>
                {messages.infoWeSharePara2P1}{' '}
                <a href="#restrictions">{messages.infoWeSharePara2P2}</a>
                {' '}{messages.infoWeSharePara2P3}
              </P>
            </P>
            <BulletList ml2>
              <ListItem>{messages.infoWeShareList1Point1}</ListItem>
              <ListItem>{messages.infoWeShareList1Point2}</ListItem>
              <ListItem>{messages.infoWeShareList1Point3}</ListItem>
              <ListItem>{messages.infoWeShareList1Point4}</ListItem>
            </BulletList>
            <P mt3>
              {messages.infoWeSharePara3}
            </P>
            <BulletList ml2>
              <ListItem>{messages.infoWeShareList2Point1}</ListItem>
              <ListItem>{messages.infoWeShareList2Point2}</ListItem>
              <ListItem>{messages.infoWeShareList2Point3}</ListItem>
              <ListItem>{messages.infoWeShareList2Point4}</ListItem>
            </BulletList>
            <P mt3>
              {messages.infoWeSharePara4}
            </P>
            <P mt3>
              <div id="howWeProtect" ref={this.howWeProtect}>
                <Subtitle>{messages.howWeProtectHeading}</Subtitle>
              </div>
              <P>{messages.howWeProtectPara1}</P>
              <P>{messages.howWeProtectPara2}</P>
            </P>
            <P mt3>
              <div id="restrictions" ref={this.restrictions}>
                <Subtitle>{messages.restrictionsHeading}</Subtitle>
              </div>
              <P>{messages.restrictionsPara1}</P>
              <P>
                {messages.restrictionsPara2P1}{' '}
                <a href="/a/settings" rel="noopener noreferrer">{messages.restrictionsPara2P2}</a>
              </P>
              <P>
                <Span b>{messages.restrictionsPara3P1}</Span>
                {' '}{messages.restrictionsPara3P2}
              </P>
              <P>{messages.restrictionsPara4}</P>
              <P>{messages.restrictionsPara5}</P>
            </P>
            <P mt3>
              <div id="choices" ref={this.choices}>
                <Subtitle>{messages.choicesHeading}</Subtitle>
              </div>
              <P>{messages.choicesPara1}</P>
              <P>{messages.choicesPara2}</P>
              <P>{messages.choicesPara3}</P>
              <P>{messages.choicesPara4}</P>
              <P>
                {messages.choicesPara5P1}{' '}<br/>
              </P>
              <P>
                {messages.choicesPara5P01}{' '}
                <a href="https://support.unroll.me/hc/en-us/requests/new?ticket_form_id=360000640791" rel="noopener noreferrer">{messages.choicesPara5P2}</a>
              </P>
              <P>
                {messages.choicesPara6P1}{' '}
                <a
                  href="https://support.unroll.me/hc/en-us/articles/360060115832-How-do-I-delete-my-Unroll-Me-Account-"
                  target="_blank"
                  rel="noopener noreferrer">
                  {messages.choicesPara6P2}
                </a>
              </P>
              <P>{messages.choicesPara7}</P>
              <P>{messages.choicesPara8}</P>
              <P>{messages.choicesPara9}</P>
            </P>

            <P mt3>
              <div id="rightsEEA" ref={this.rightsEEA}>
                <Subtitle>{messages.rightsEEAHeading}</Subtitle>{' '}
              </div>
              <P>{messages.rightsEEAPara1}</P>
              <BulletList ml2>
                <ListItem>{messages.rightsEEAPara2Point1}</ListItem>
                <ListItem>{messages.rightsEEAPara2Point2}</ListItem>
                <ListItem>{messages.rightsEEAPara2Point3}</ListItem>
                <ListItem>{messages.rightsEEAPara2Point4}</ListItem>
              </BulletList>
              <P>{messages.rightsEEAPara3}</P>
              <P>
                {messages.rightsEEAPara4Part1}
                {' '}<Span b>{messages.rightsEEAPara4Part2}</Span>{' '}
                {messages.rightsEEAPara4Part3}
              </P>
              <P>
                {messages.rightsEEAPara5Part1}{' '}
                <a
                  href="https://ec.europa.eu/info/about-european-commission/contact_en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {messages.rightsEEAPara5Part2}
                </a>
                {messages.rightsEEAPara5Part3}
              </P>
              <P>
                <Span i>{messages.rightsEEALegalHeading}</Span>
              </P>
              <P>{messages.rightsEEALegalPara1}</P>
              <BulletList ml2>
                <ListItem>{messages.rightsEEALegalPoint1}</ListItem>
                <ListItem>{messages.rightsEEALegalPoint2}</ListItem>
                <ListItem>{messages.rightsEEALegalPoint3}</ListItem>
              </BulletList>
            </P>
            <P mt3>
              <div id="rights" ref={this.rights}>
                <Subtitle>{messages.rightsHeading}</Subtitle>
              </div>
              <P>{messages.rightsPara1P1}</P>
              <P>{messages.rightsPara1P2}</P>
              <P>{messages.rightsPara1P3}</P>
              <P>
                <Span b>{messages.rightsPara2P1}</Span>
                {' '}{messages.rightsPara2P2}
              </P>
              <P>
                <Span b>{messages.rightsPara2P3}</Span>
                {' '}{messages.rightsPara2P4}
              </P>
              <TableContainer>
                <table>
                  <thead>
                  <tr>
                    <th>Categories of personal information collected</th>
                    <th>Categories of sources</th>
                    <th>Business or commercial purpose for collecting</th>
                  </tr>
                  </thead>
                  <tr>
                    <td style={{width:"30%"}}>Personal and online identifiers (such as first and last name, email address, or unique online identifiers)</td>
                    <td style={{width:"30%"}}>
                      Consumers<br/>
                      Service<br/><br/>
                      Providers
                    </td>
                    <td style={{width:"30%"}}>
                      Performing email management services<br />
                      Internal Research<br />
                      Internal Operations<br />
                      Activities to improve services<br />
                      Develop and Prepare<br />
                      Measurement Products<br />
                      Authenticating Users<br />
                      Legal compliance<br />
                      Debugging<br />
                    </td>
                  </tr>
                  <tr>
                    <td>Commercial or transactions information (such as products or services purchased or applied for)</td>
                    <td>
                      Consumers<br/>
                      Contents of Email<br/>
                    </td>
                    <td>
                      Performing email management services<br />
                      Internal Research<br />
                      Internal Operations<br />
                      Activities to improve services<br />
                      Develop and Prepare<br />
                      Measurement Products<br />
                      Legal compliance<br />
                    </td>
                  </tr>
                  <tr>
                    <td>Other information about you that is linked to the personal information above</td>
                    <td>
                      Consumers<br/><br/>
                      Service<br/>
                      Providers<br/>
                    </td>
                    <td>
                      Performing email management services<br />
                      Internal Research<br />
                      Internal Operations<br />
                      Activities to improve services<br />
                      Develop and Prepare<br />
                      Measurement Products<br />
                      Legal compliance<br />
                    </td>
                  </tr>
                  <tr>
                    <td>Sensitive Personal Information</td>
                    <td>
                      Contents of Email<br/>
                    </td>
                    <td>
                      Performing email management services<br />
                      Internal Research<br />
                      Internal Operations<br />
                      Activities to improve services<br />
                      Develop and Prepare<br />
                      Measurement Products<br />
                    </td>
                  </tr>
                </table>
              </TableContainer>
              <P>
                <Span b>{messages.rightsPara2P5}</Span>
                {' '}{messages.rightsPara2P6}
              </P>
              <TableContainer>
                <table>
                  <thead>
                  <tr>
                    <th>Categories of personal information sold or shared to third parties</th>
                    <th>Categories of third parties to whom the information was sold or shared</th>
                    <th>Business or commercial purpose for selling or sharing</th>
                  </tr>
                  </thead>
                  <tr>
                    <td style={{width:"30%"}}>Personal and online identifiers (such as first and last name, email address, or unique online identifiers)</td>
                    <td style={{width:"30%"}}>
                      Measurement<br/>
                      Product Customers<br/>
                      (such as e-commerce businesses and investment companies)<br/>
                      Affiliates
                    </td>
                    <td style={{width:"30%"}}>
                      Performing email management services<br />
                      Internal Research<br />
                      Internal Operations<br />
                      Activities to improve services<br />
                      Develop and Prepare<br />
                      Measurement Products<br />
                      Authenticating Users<br />
                      Legal compliance<br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Commercial or transactions information (such as products or services purchased or applied for)
                    </td>
                    <td>
                      Measurement<br/>
                      Product Customers<br/>
                      (such as e-commerce businesses and investment companies)<br/>
                      Affiliates
                    </td>
                    <td>
                      Performing email management services<br />
                      Internal Research<br />
                      Internal Operations<br />
                      Activities to improve services<br />
                      Develop and Prepare<br />
                      Measurement Products<br />
                      Authenticating Users<br />
                      Legal compliance<br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Other information about you that is linked to the personal information above
                    </td>
                    <td>
                      Measurement<br/>
                      Product Customers<br/>
                      (such as e-commerce businesses and investment companies)<br/>
                      Affiliates
                    </td>
                    <td>
                      Performing email management services<br />
                      Internal Research<br />
                      Internal Operations<br />
                      Activities to improve services<br />
                      Develop and Prepare<br />
                      Measurement Products<br />
                      Authenticating Users<br />
                      Legal compliance<br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Sensitive Personal Information
                    </td>
                    <td>
                      Measurement<br/>
                      Product Customers<br/>
                      (such as e-commerce businesses and investment companies)<br/>
                      Affiliates
                    </td>
                    <td>
                      Performing email management services<br />
                      Internal Research<br />
                      Internal Operations<br />
                      Activities to improve services<br />
                      Develop and Prepare<br />
                      Measurement Products<br />
                    </td>
                  </tr>
                </table>
              </TableContainer>

              <P>
                <Span b>{messages.rightsPara2P7}</Span>
                {' '}{messages.rightsPara2P8}
              </P>
              <TableContainer>
                <table>
                  <thead>
                  <tr>
                    <th>Categories of personal information disclosed for a business purpose to third parties</th>
                    <th>Categories of third parties to whom the information was disclosed</th>
                    <th>Business or commercial purpose for disclosing</th>
                  </tr>
                  </thead>
                  <tr>
                    <td style={{width:"30%"}}>Personal and online identifiers (such as first and last name, email address, or unique online identifiers)</td>
                    <td style={{width:"30%"}}>
                      Service Providers<br/>
                      Measurement<br/>
                      Product Customers<br/>
                      (such as e-commerce businesses and investment companies)<br/>
                      Parent and Affiliates
                    </td>
                    <td style={{width:"30%"}}>
                      Email management services<br/>
                      Internal Research<br/>
                      Internal Operations<br/>
                      Activities to improve services<br/>
                      Develop Measurement Products<br/>
                      Authenticating Users<br/>
                      Legal compliance<br/>
                      Debugging<br/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Commercial or transactions information (such as products or services purchased or applied for)
                    </td>
                    <td >
                      Service Providers<br/>
                      Measurement<br/>
                      Product Customers<br/>
                      (such as e-commerce businesses and investment companies)<br/>
                      Parent and Affiliates
                    </td>
                    <td >
                      Email management services<br/>
                      Internal Research<br/>
                      Internal Operations<br/>
                      Activities to improve services<br/>
                      Develop Measurement Products<br/>
                      Authenticating Users<br/>
                      Legal compliance<br/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Other information about you that is linked to the personal information above
                    </td>
                    <td >
                      Service Providers<br/>
                      Measurement<br/>
                      Product Customers<br/>
                      (such as e-commerce businesses and investment companies)<br/>
                      Parent and Affiliates
                    </td>
                    <td >
                      Email management services<br/>
                      Internal Research<br/>
                      Internal Operations<br/>
                      Activities to improve services<br/>
                      Develop Measurement Products<br/>
                      Authenticating Users<br/>
                      Legal compliance<br/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Sensitive Personal Information
                    </td>
                    <td >
                      Service Providers<br/>
                      Measurement<br/>
                      Product Customers<br/>
                      (such as e-commerce businesses and investment companies)<br/>
                      Parent and Affiliates
                    </td>
                    <td >
                      Email management services<br/>
                      Internal Research<br/>
                      Internal Operations<br/>
                      Activities to improve services<br/>
                      Develop and Prepare<br/>
                      Measurement Products<br/>
                    </td>
                  </tr>
                </table>
              </TableContainer>

            </P>
            <P mt3>
              <P>
                <Span b>{messages.rightsPersonalHeading}</Span>
                {' '}{messages.rightsPersonalPara1}
              </P>
              <BulletList ml2>
                <ListItem>
                  {messages.rightsPersonalList1Point1}
                </ListItem>
                <ListItem>
                  {messages.rightsPersonalList1Point2}
                </ListItem>
                <ListItem>
                  {messages.rightsPersonalList1Point3}
                </ListItem>
                <ListItem>
                  {messages.rightsPersonalList1Point4}
                </ListItem>
                <ListItem>
                  {messages.rightsPersonalList1Point5}
                </ListItem>
                <ListItem>
                  {messages.rightsPersonalList1Point6}
                </ListItem>
              </BulletList>
            </P>
            <P mt3>
              <div id="duration" ref={this.duration}>
                <Subtitle>{messages.durationHeading}</Subtitle>{' '}
              </div>
              <P>{messages.durationPara1}</P>
            </P>
            <P mt3>
              <div id="transfers" ref={this.transfers}>
                <Subtitle>{messages.transfersHeading}</Subtitle>
              </div>
              <P>{messages.transfersPara1}</P>
              <P>
                {messageDescriptors.transfersPara2P1.defaultMessage}{' '}
                <a href="mailto:privacy@unroll.me" onClick={() => {
                  track(eventConstants.common.supportLinkClicked, {
                    [eventConstants.common.supportType.propName]: eventConstants.common.supportType.email,
                    [eventConstants.common.supportCta.propName]: eventConstants.common.supportCta.privacyEmail
                  })
                }}>privacy@unroll.me</a>
                {messageDescriptors.transfersPara2P2.defaultMessage}
                <a
                  href="https://nielseniq.com/global/en/legal/gdpr-operating-entities/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {messageDescriptors.transfersPara2P3.defaultMessage}
                </a>.
              </P>
            </P>
            <P mt3>
              <div id="links" ref={this.links}>
                <Subtitle>{messages.linksHeading}</Subtitle>
              </div>
              <P>{messages.linksPara1}</P>
            </P>
            <P mt3>
              <div id="updates" ref={this.updates}>
                <Subtitle>{messages.updatesHeading}</Subtitle>
              </div>
              <P>{messages.updatesPara1}</P>
            </P>
            <P mt3>
              <div id="contact" ref={this.contact}>
                <Subtitle>{messages.contactHeading}</Subtitle>
              </div>
              <P>
                {messages.contactPara1}{' '}
                <a href="mailto:privacy@unroll.me" onClick={() => {
                  track(eventConstants.common.supportLinkClicked, {
                    [eventConstants.common.supportType.propName]: eventConstants.common.supportType.email,
                    [eventConstants.common.supportCta.propName]: eventConstants.common.supportCta.privacyEmail
                  })
                }}>privacy@unroll.me</a>.
              </P>
              <P>
                <div>NielsenIQ</div>
                <div>200 W Jackson Blvd</div>
                <div>Chicago, IL 60606USA</div>
                <div>Attn: Legal Dept.</div>
              </P>
              <P>
                {messages.contactPara2}
              </P>
              <P>
                <div>NielsenIQ</div>
                <div>NielsenIQ House</div>
                <div>Oxford Business Park South</div>
                <div>John Smith Drive</div>
                <div>Oxford OX4 2WB</div>
                <div>UK</div>
                <div>Attn: Legal Dept.</div>
              </P>
            </P>
          </Wrapper>
        </Section>
      </Layout>
    )
  }
}

export default injectIntl(Privacy)
